import { useEffect, useState } from 'react';
import { Box, Paper } from '@mui/material';
import { BlogPost, BlogPostProps } from '../../Components/BlogPost';
import { css } from '@emotion/css';
import { useSearchParams } from 'react-router-dom';

export const HomePage = () => {

  const [ searchParams, setSearchParams ] = useSearchParams();

  const [ posts, setPosts ] = useState<BlogPostProps[]>([]);
  const [ error, setError ] = useState<boolean>(false);

  useEffect(() => {
    const fetchPosts = async () => {
      const response = await fetch("https://api.potaten.com/b/blog/posts");
      const data = await response.json();
      if (data.error) {
        setError(true);
        return;
      }
      setPosts(data);
    };


    fetchPosts();

  }, []);

  return (
    <Box height="100%" width="100%" display="flex" justifyContent="center" alignItems="center" bgcolor="#4A5859">
      <Box width="100%" maxWidth="1000px" height="95%" display="flex" flexDirection="column" justifyContent="flex-start" alignItems="center" m={2}>
        <Paper elevation={1} style={{ width: "100%", height: "100%", padding: "16px", backgroundColor: "#F4B860", overflow: "scroll" }}>
          {
            posts.map(post =>
              <Box key={post.title} width="100%" marginTop={2}>
                <BlogPost {...post} focused={searchParams.get("postId") == post.createdAt?.toString()} />
              </Box>
            )
          }
        </Paper>
      </Box>

    </Box>
  );

};