import { Box, Card, CardContent, CardHeader } from "@mui/material";
import { FC, useRef } from "react";
import MD from "react-markdown";
import moment from "moment";
import { css } from "@emotion/css";

export type BlogPostProps = {
    title: string;
    updatedAt: number;
    createdAt: number;
    body: string;
    focused?: boolean;
};

export const BlogPost: FC<BlogPostProps> = ({ title, updatedAt, body, focused }) => {

    const ref = useRef<HTMLDivElement>(null);

    if (focused && ref.current) {
        ref.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }

    return (
        <Card ref={ref} style={{ backgroundColor: focused ? "#26292C" : "#32373B" }}>
            <CardHeader title={title} subheader={moment.unix(updatedAt).format("YYYY.MM.DD")} subheaderTypographyProps={{ color: "#F4D6CC" }} style={{ color: "#F4D6CC" }} />
            <CardContent style={{ textAlign: "start", color: "#F4D6CC" }}>
                <MD>{body}</MD>
            </CardContent>
        </Card>
    );
};